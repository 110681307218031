<!--文章列表-->
<template>
  <!--路径s-->
  <div class="path">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="24" :md="22" :lg="20" >
        <span><router-link to="/"> 首页</router-link></span> &nbsp; &gt; &nbsp; 通知公告
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--路径s--> 
  <div class="box-p">
    <el-row :gutter="0">
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :xs="0" :sm="1" :md="1" :lg="1" ></el-col>
      <el-col :xs="24" :sm="22" :md="20" :lg="18">
        <div class="newslist">
            <ul>
                <li v-for="(item,index) in messageData" :key="index">
                  <span>· <router-link :to="{ path: '/message/message', query: { id: item.id }}" target="_blank">{{item.title.substring(0,50)}}</router-link></span><time>{{formatDate(item.datetimes)}}</time>
                </li>         
            </ul>
        </div>
        <div class="pagination">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[10, 15, 20, 30, 40]"
              :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total='tableTotal'>
            </el-pagination>
        </div>        
      </el-col>
      <el-col :xs="0" :sm="1" :md="1" :lg="1"></el-col>
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
    </el-row>
  </div>  
</template>

<script>
import {formatDate} from '@/utils/datetime_utils.js'
export default {
  name: 'messagelist',  
  data () {
    return {
      currentPage: 1,
      pagesize: 20,
      tableTotal: 0,
      messageData: [],
      title: '美专家：中国已加入超级航母俱乐部，这个俱乐部里只有中美两国。中国已加入超级航母俱乐部，这个俱乐部里只有中美两国。'
    }
  },
  mounted () {
    this.getListData()
  },
  methods: {
    // pageSize 新每页条数改变时触发(每页下拉显示数据)
    handleSizeChange (size) {
      this.pagesize = size
      this.getListData()
    },
    // 新当前页改变时触发(点击第几页)
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage
      this.getListData()
    },
    async getListData () {
      const params = '?status=0&size='+ this.pagesize + '&current=' + this.currentPage
      const { data: ret } = await this.$http.get1(this.$api.messagelist + params)      
      // console.log('ret=', ret)
      if (ret.code === 1) {
        this.messageData = ret.data.records
        this.tableTotal = ret.data.total
        // console.log('rows=', this.messageData)
      } else {
        this.$message.error(ret.msg)        
      }     
    },
    // 显示时间格式'yyyy-MM-dd hh:mm:ss'
    formatDate(time){
      var data = new Date(time)
      return formatDate(data,'yyyy-MM-dd hh:mm')
    }    
  }
}
</script>

<style scoped>
</style>